import React, {
    Fragment,
    useState,
    useCallback,
  } from 'react'  
import update from 'immutability-helper'
import { navigate } from "gatsby"
import { withFirebase } from '../../../providers/firebase'
import {
    Page,
    Frame,
    Layout,
    Card,
    EmptyState,
    Stack,
    Heading,
    Icon,
    ButtonGroup,
    Button,
    InlineError,
    TextContainer,
    Toast,
    Banner,
    Link,
  } from "@shopify/polaris"
import {
    QuickSaleMajor,
    ListMajor,
    ReplaceMajor,
    QuestionMarkMajor,
    ExternalMinor,
  } from '@shopify/polaris-icons';
import styled from 'styled-components'
const OfferTypeWrapper = styled.div`
    .offer-type-container {
      .offer-type {
        .offer-type-title {
            margin-bottom: 15px;
        }
        .offer-type-option {
            padding: 15px 15px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            .offer-type-option-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 25px;
                ul {
                    padding-left: 15px;
                    font-size: 12px;
                    color: #A4A4A4;
                }
            }
        }
      }
      .errors {
          padding: 5px 0;
      }
      .note {
          margin: 30px 0 150px;
          p {
              color: #A4A4A4;
          }
      }
    }
    .save-buttons {
        .Polaris-ButtonGroup__Item:first-of-type {
            button {
                color: #008060;
                svg {
                    fill: #008060;
                }
            }
        }
        .Polaris-ButtonGroup__Item:last-of-type {
            button {
                background: #6C7175;
            }
        }
    }
`

function CheckoutInstruction(props) {
    const { token, shop, location, firebase, host } = props
    const [positionType, setPositionType] = useState("PAEP")
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [active, setActive] = useState(null);
    const onPositionTypeChange = (type) => {
        setPositionType(type)
    }
    const handleManually = () => {
        navigate(
            `/app/offers`,
            {
            replace: true,
            }
        )
    }
    const handleSavePosition = async () => {
        if (isSubmitting) {
            return
        }
        setIsSubmitting(true)
        try {
            /*
            const result = await addCodeSnippet({
                type: positionType
            }, token, shop, host);
            if(200 === result.status){
                setActive("Success")
                navigate(
                    `/app/offers`,
                    {
                    replace: true,
                    }
                )
            }else{
                setActive("Error")
            }
            */
            setActive("Error")
        }
        catch (e) {
            setActive("Error")
            /*
            if(window && window.$crisp){
                window.$crisp.push(["do", "chat:open"]);
               }
               */
        }
        setIsSubmitting(false)
    }
    const toggleActive = () => setActive(null)
    const toastMarkup = active && "Success" === active ? (
        <Toast content="Position saved!" onDismiss={toggleActive} />
      ) : (active && "Error" === active ? (
        <Toast content={<div><h2 style={{fontWeight:'bold', textAlign:'left'}}>Please contact our support to install the widget</h2><p style={{margin:'20px 0', textAlign:'left'}}>Your offer was saved, but we weren’t able to add the widget due to assets api being deprecated.</p><p style={{textAlign:'left'}}>You can contact us in the <a href="javascript:void(0)" style={{color:'white'}} onClick={ () => window.$crisp.push(["do", "chat:open"])}>chat on the bottom right.</a></p></div>} 
        onDismiss={toggleActive} error duration={20000}/>
      ) : null);
    return (
        <Frame>
        <Page 
            breadcrumbs={[{content: 'Offers', url: '/app/offers'}]}
            title={
                `Create Offer`
            }
        >
        <Layout>
            <Layout.Section oneHalf>
                <Card>
                <Card.Section>
                    <OfferTypeWrapper>
                        <div className='offer-type-container'>
                            <div className='offer-type'>
                                <div className='offer-type-title'>
                                    <TextContainer>
                                        <Heading>Add the widget to your checkout.liquid theme file</Heading>
                                        <p>
                                        Where do you want the offer to show on the checkout page?
                                        </p>
                                    </TextContainer>
                                </div>
                                <div className="Polaris-Card" style={ "PAEP" === positionType ? { border: '3px solid #008060' } : {}}>
                                <div className="Polaris-Card">
                                    <div className='offer-type-option' onClick={() => { 
                                            onPositionTypeChange('PAEP')
                                        }}>
                                        <div className="offer-type-option-container">
                                            <div>
                                                <Icon
                                                source={QuickSaleMajor}/>
                                            </div>
                                            <div className='position-type-details'>
                                                <p>Position above Express Payment</p>
                                                <ul>
                                                    <li>Mobile optimized, always visible</li>
                                                    
                                                    <li>Shows just under Cart {'>'} Info {'>'} Shipping {'>'} Payment steps</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <div className="Polaris-Card" style={"POS" === positionType ? { border: '3px solid #008060'} : {}}>
                                    <div className="Polaris-Card">
                                        <div className='offer-type-option' onClick={() => { 
                                                onPositionTypeChange('POS')
                                            }}>
                                            <div className="offer-type-option-container">
                                                <div>
                                                    <Icon
                                                    source={ListMajor}/>
                                                </div>
                                                <div className='position-type-details'>
                                                    <p>Position in Order Summary</p>
                                                    <ul>
                                                        <li>Shows below cart contents</li>
                                                        <li>Nested in order summary (collapsed by default) on mobile</li>
                                                    </ul>
                                                </div>
                                            </div>
                                           
                                        </div>
                                    </div>
                                </div>

                                <div className="Polaris-Card" style={"OPMD" === positionType ? { border: '3px solid #008060'} : {}}>
                                    <div className="Polaris-Card">
                                        <div className='offer-type-option' onClick={() => { 
                                                onPositionTypeChange('OPMD')
                                            }}>
                                            <div className="offer-type-option-container">
                                                <div>
                                                    <Icon
                                                    source={ReplaceMajor}/>
                                                </div>
                                                <div className='position-type-details'>
                                                    <p>Optimize position for mobile and desktop</p>
                                                    <ul>
                                                        <li>Mobile: shows above express payment</li>
                                                        <li>Desktop: shows below cart contents</li>
                                                    </ul>
                                                </div>
                                            </div>
                                           
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div style={{paddingTop:'30px'}}>
                        <Stack vertical spacing="loose">
                        <Stack distribution="center">
                                <Button  
                                        onClick={
                                            () => {
                                                window.open(`https://help.upsellplus.com/en/article/how-to-manually-install-the-checkout-widget-to-the-checkoutliquid-theme-file-16pyvy5/`, '_blank')
                                            }
                                        } 
                                        primary>
                                        <Stack distribution="center" alignment='center' spacing='tight'>
                                            
                                            <span>Open instructions</span>
                                            <Icon
                                            source={ExternalMinor}
                                            color="base"
                                            />
                                        </Stack>
                                        </Button>                    
                        </Stack>
                        <div className='save-buttons'>
                            <Stack distribution="center">
                                <ButtonGroup>
                                    
                                    <Button  
                                        onClick={
                                            () => {
                                                if(window && window.$crisp){
                                                    window.$crisp.push(["do", "chat:open"]);
                                                } 
                                            }
                                            
                                        } 
                                        plain >
                                        <Stack distribution="center" alignment='center' spacing='tight'>
                                            <Icon
                                            source={QuestionMarkMajor}
                                            color="base"
                                            />
                                            <span>Ask our 24/7 support</span>
                                        </Stack>
                                        </Button>
                                    <Button onClick={handleManually} primary>Mark as done</Button>        
                                </ButtonGroup>
                            </Stack>
                        </div>
                        </Stack>
                        </div>
                    </OfferTypeWrapper>
                </Card.Section>
                </Card>
            </Layout.Section>
            <Layout.Section oneHalf>
                <div
                  style={{
                    position: 'fixed',
                    paddingRight: '30px'
                  }}
                >
                  <TextContainer>
                    <Heading>{"POS" === positionType ? "Preview: Position in Order Summary":("PAEP" === positionType ? "Preview: Position above Express Payment" : "Preview: Optimize position for mobile and desktop") }</Heading>
                 </TextContainer>
                  <img src={"POS" === positionType ? "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/POS.png?alt=media&token=04d46a5b-4b14-4c93-85ca-4c003a45da66" : ("PAEP" === positionType ? "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/PAEP.png?alt=media&token=f431a7a3-f414-4499-ae72-c730f32ae26e" : "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/OPMD.png?alt=media&token=e5c5ee23-6f9c-4286-8ea8-ce4273062262") } style={{width:'100%'}}/>
                </div>
            </Layout.Section>
        </Layout>
        {toastMarkup}
    </Page>
    </Frame>
    )
}

export default withFirebase(CheckoutInstruction);